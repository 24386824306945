body {
  background-color: #292e39;
  margin: 0;
  padding: 0;
}

#example-table {
  width: 100%;
  max-width: 1000px;
  height: 100%;
  margin: auto;
  color: #fff !important;
}

#example-table .tabulator-tableHolder {
  background-color: #2e3440 !important;
}

#example-table .tabulator-header {
  background-color: #3b4252 !important;
}

h2 {
  text-align: center;
  color: #f5f5f5;
  margin: 20px auto;
}
/*# sourceMappingURL=index.7682dc09.css.map */
